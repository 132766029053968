import React from 'react'

const NotFoundPage = () => (
  <div className='container' style={{ paddingTop: '80px' }}>
    <h1>NOT FOUND</h1>
    <p>This page has not been found.</p>
  </div>
)

export default NotFoundPage
